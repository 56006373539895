import BaseLayout from "@components/BaseLayout"
import Post from "@components/Post"
import routes from "@utils/routes"
import { graphql } from "gatsby"
import React from "react"
import Seo from "../components/Seo"
import Hero from "@components/Hero"
import SearchInput from "@components/SearchInput"
import { useNavigateToResultsList } from "@utils/hooks/useNavigateToResultsList"
import { useTranslate } from "@utils/hooks/useTranslate"
import { SimilarArticlesFactory } from "@utils/SimilarPostsFactory"

function BlogPost({ data }) {
  const {
    allPosts,
    markdownRemark: {
      frontmatter: {
        title,
        seoTitle,
        date,
        description,
        category,
        keywords,
        image,
        language,
      },
      htmlAst,
      timeToRead,
      fields: { categorySlug, slug },
    },
  } = data
  const search = useNavigateToResultsList()
  const translate = useTranslate()

  const similarPosts = new SimilarArticlesFactory(allPosts.edges, slug)
    .setMaxArticles(5)
    .setCategory(category)
    .setTags(keywords)
    .getArticles()
    .map(article => article.article)

  return (
    <BaseLayout
      underMenuItem={routes.blog}
      isDark
      hero={
        <Hero
          title="blog.hero.title"
          subtitle="blog.hero.subtitle"
          rightSide={
            <SearchInput
              placeholder={translate("blog.searchPlaceholder")}
              onSubmit={term => search(term)}
            />
          }
        />
      }
    >
      <Seo
        title={seoTitle || title}
        image={image.publicURL}
        description={description}
        keywords={keywords}
        article
      />
      <Post
        {...{
          image,
          language,
          title,
          date,
          description,
          category,
          htmlAst,
          slug,
          timeToRead,
          categorySlug,
          similarPosts,
        }}
      />
    </BaseLayout>
  )
}

export const query = graphql`
  query BlogPost($slug: String!, $postsLanguage: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        seoTitle
        description
        date
        language
        image {
          childImageSharp {
            fluid(maxHeight: 500) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
        category
        keywords
      }
      fields {
        slug
        categorySlug
      }
      html
      htmlAst
      timeToRead
    }
    allPosts: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/content/posts/*.md" }
        frontmatter: { language: { eq: $postsLanguage } }
      }
    ) {
      edges {
        node {
          frontmatter {
            category
            date
            description
            title
            language
            keywords
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
            categorySlug
          }
          timeToRead
        }
      }
    }
  }
`

export default BlogPost
