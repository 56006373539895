import ImagesSVG from "@components/ImagesSVG"
import AsideBar from "@components/AsideBar"
import SocialShared from "@constants/socialShared"
import { FormattedDate } from "gatsby-plugin-intl"
import React from "react"
import * as Style from "./Post.styles"
import { customRenderMarkdown } from "@utils/customRenderMarkdown"

function Post({
  title,
  category,
  date,
  image,
  language,
  description,
  htmlAst,
  slug,
  timeToRead,
  categorySlug,
  similarPosts,
}) {
  return (
    <Style.ContentWrapper>
      <Style.PostWrapper>
        <Style.CoverImageWrapper
          categorySlug={categorySlug}
          category={category}
          language={language}
          image={image.childImageSharp.fluid}
        />
        <Style.Body>
          <Style.Header>
            <Style.TimeToRead timeToRead={timeToRead} />
            <Style.SocialMediaWrapper>
              {SocialShared(slug, title).map((media, index) => (
                <Style.SocialMediaItem
                  key={index}
                  link={media.link}
                  icon={<ImagesSVG svg={media.icon} />}
                />
              ))}
            </Style.SocialMediaWrapper>
          </Style.Header>
          <Style.Title>{title}</Style.Title>
          <Style.Subtitle>{description}</Style.Subtitle>
          <Style.Date>
            <FormattedDate
              day="2-digit"
              month="long"
              year="numeric"
              value={date}
            />
          </Style.Date>
          <Style.Content>{customRenderMarkdown(htmlAst)}</Style.Content>
          <Style.SocialMediaWrapper>
            {SocialShared(slug).map((media, index) => (
              <Style.SocialMediaItem
                key={index}
                link={media.link}
                icon={<ImagesSVG svg={media.icon} />}
              />
            ))}
          </Style.SocialMediaWrapper>
        </Style.Body>
      </Style.PostWrapper>
      <Style.AsideBarWrapper>
        <AsideBar data={similarPosts} />
      </Style.AsideBarWrapper>
    </Style.ContentWrapper>
  )
}

export default Post
