import { Botao } from "@xapps/design-system"
import React from "react"
import { Send } from "react-feather"
import { Flex, Heading } from "rebass"

export const Wrapper = props => (
  <Flex
    sx={{
      flexDirection: "column",
      bg: "white",
      alignItems: "center",
      mb: 4,
      paddingX: 3,
      paddingY: 4,
      borderRadius: ["none", "box", "box"],
    }}
    {...props}
  />
)

export const Title = props => (
  <Heading as="h2" sx={{ mb: 3, color: "blueDark" }} {...props} />
)

export const SendIcon = props => (
  <Flex sx={{ ml: 10 }} {...props}>
    <Send />
  </Flex>
)

export const SubmitButton = props => (
  <Botao
    variant="apoio"
    style={{ borderRadius: 50 }}
    sx={{ minWidth: ["100%", "fit-content"] }}
    {...props}
  />
)
