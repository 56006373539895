import { includes, orderBy } from "lodash"

export class SimilarArticlesFactory {
  constructor(articles, currentArticleSlug) {
    this.articles = articles.filter(
      aArticle => aArticle.node.fields.slug !== currentArticleSlug
    )

    this.currentArticleSlug = currentArticleSlug
    this.maxArticles = 3
    this.category = null
    this.tags = []
  }

  setMaxArticles(m) {
    this.maxArticles = m
    return this
  }

  static splitTags(mergedTags) {
    return mergedTags ? mergedTags.split(",").map(tag => tag.trim()) : []
  }

  setCategory(aCategory) {
    this.category = aCategory
    return this
  }

  setTags(keywords) {
    this.tags = SimilarArticlesFactory.splitTags(keywords)
    return this
  }

  getArticles() {
    const { category, tags, articles, maxArticles } = this
    const identityMap = {}

    if (!!tags === false || tags.length === 0) {
      console.error("SimilarArticlesFactory: Tags not provided, use setTags().")
      return []
    }

    if (!!category === false) {
      console.error(
        "SimilarArticlesFactory: Category not provided, use setCategory()."
      )
      return []
    }

    function getSlug(article) {
      return article.node.fields.slug
    }

    function addToMap(article) {
      const slug = getSlug(article)
      if (!identityMap.hasOwnProperty(slug)) {
        identityMap[slug] = {
          article: article,
          points: 0,
        }
      }
    }

    function addCategoryPoints(article, category) {
      const categoryPoints = 2
      const slug = getSlug(article)

      if (article.node.frontmatter.category === category) {
        identityMap[slug].points += categoryPoints
      }
    }

    function addTagsPoints(article, tags) {
      const tagPoint = 1
      const slug = getSlug(article)
      const articleTags = SimilarArticlesFactory.splitTags(
        article.node.frontmatter.keywords
      )

      articleTags.forEach(aTag => {
        if (includes(tags, aTag)) {
          identityMap[slug].points += tagPoint
        }
      })
    }

    function getIdentityMapAsArray() {
      return Object.keys(identityMap).map(slug => identityMap[slug])
    }

    for (const article of articles) {
      addToMap(article)
      addCategoryPoints(article, category)
      addTagsPoints(article, tags)
    }

    const arrayIdentityMap = getIdentityMapAsArray()
    const similarArticles = orderBy(arrayIdentityMap, ["points"], ["desc"])
    return similarArticles.splice(0, maxArticles)
  }
}
