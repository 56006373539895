import Translate from "@components/Translate"
import { Input } from "@rebass/forms"
import { useTranslate } from "@utils/hooks/useTranslate"
import React from "react"
import * as Style from "./Newsletter.styles"
import { useSubscribeToNewsletterForm } from "@utils/hooks/useSubscribeToNewsletterForm"

function Newsletter() {
  const {
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
  } = useSubscribeToNewsletterForm()
  const translateMessage = useTranslate()

  return (
    <Style.Wrapper as="form" onSubmit={handleSubmit}>
      <Style.Title>
        <Translate id="newsletter.title" />
      </Style.Title>
      <Input
        variant="borderedInput"
        type="email"
        required
        name="email"
        onChange={handleChange}
        value={values.email}
        placeholder={translateMessage("newsletter.emailPlaceholder")}
        mb={4}
      />
      <Style.SubmitButton disabled={isSubmitting} type="submit">
        <Translate id="newsletter.submitLabel" />
      </Style.SubmitButton>
    </Style.Wrapper>
  )
}

export default Newsletter
