/** @jsx jsx */
import ContentContainer from "@components/Content"
import Translate from "@components/Translate"
import TimeIcon from "@images/svg/time.svg"
import Img from "gatsby-image"
import { Link } from "gatsby-plugin-intl"
import { Box, Flex, Heading, Text } from "rebass"
import { jsx } from "theme-ui"

export const ContentWrapper = props => (
  <ContentContainer
    sx={{
      flexDirection: ["column", "column", "row"],
      paddingX: [0, "40px", 3],
      "& > *:not(:last-child)": {
        mb: [3, 3, 0],
        mr: [0, 0, 3],
      },
    }}
    {...props}
  />
)

export const Body = props => <Box paddingX="4" paddingY="3" {...props} />

export const PostWrapper = props => (
  <Flex
    backgroundColor="white"
    flexDirection="column"
    sx={{ minWidth: ["300px", "400px", "450px"], width: "100%" }}
    {...props}
  />
)

export const AsideBarWrapper = props => (
  <Flex
    flexDirection="column"
    sx={{ maxWidth: ["auto", "350px"], width: "100%" }}
    {...props}
  />
)

export const CoverImageWrapper = ({
  image,
  categorySlug,
  category,
  language,
}) => {
  return (
    <Box
      mb={3}
      sx={{
        position: "relative",
      }}
    >
      <Img
        sx={{
          height: [250, 400, 500],
        }}
        fluid={image}
      />
      <Box
        sx={{
          backgroundColor: "#006BA6",
          position: "absolute",
          bottom: "0",
          left: "0",
          padding: 2,
          width: "auto",
          a: { color: "white", textDecoration: "none" },
        }}
      >
        <Link to={`/${categorySlug}`}>{category}</Link>
      </Box>
    </Box>
  )
}

export const Title = props => (
  <Heading fontFamily="body" mb="3" fontSize="5" color="heading" {...props} />
)
export const Subtitle = props => (
  <Text fontFamily="body" mt="1" color="gray" fontSize="2" {...props} />
)
export const Date = props => (
  <Text color="gray" mt="1" fontSize="1" fontStyle="italic" {...props} />
)

export const TimeToRead = ({ timeToRead }) => (
  <Flex
    sx={{
      alignItems: "center",
      color: "blue",
      margin: "10px auto 10px 0",
      "> div": { ml: 1 },
    }}
  >
    <TimeIcon />
    <div>
      <Translate id="blog.readingTime" ml="2" />
    </div>
    <div>{timeToRead}</div>
    <div>min</div>
  </Flex>
)

export const Header = ({ children }) => (
  <Flex
    sx={{
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",
    }}
  >
    {children}
  </Flex>
)

export const SocialMediaWrapper = ({ children, ...props }) => {
  return (
    <ul
      style={{
        listStyle: "none",
        marginTop: "20px",
        marginBottom: "20px",
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <Translate id="share" />
      {children}
    </ul>
  )
}

export const SocialMediaItem = ({ icon, link, ...props }) => {
  return (
    <li style={{ width: "24px", marginLeft: "16px" }}>
      <a
        sx={{
          display: "inline",
          paddingBottom: "10px",
          width: "24px",
          minWidth: "24px",
        }}
        href={link}
        target="__blank"
      >
        <Box
          sx={{
            "& svg": {
              display: "flex",
              width: "24px",
              cursor: "pointer",
              fill: "grayBlue",
              color: "grayBlue",
              path: {
                fill: "currentColor !important",
              },
              "&:hover": {
                transition: "0.2s opacity",
                opacity: 0.4,
              },
            },
          }}
        >
          {icon}
        </Box>
      </a>
    </li>
  )
}

export const Content = props => (
  <Box
    mt="5"
    sx={{
      fontSize: 2,
      maxWidth: "100%",
      lineHeight: "1.85",
      color: "text",
      fontFamily: "body",

      p: {
        fontSize: "1em",
        marginBottom: "1.3em",
        height: "auto",
        lineHeight: "1.45",
      },

      h1: {
        fontSize: 6,
        marginTop: 0,
        padding: "4em 0 !important",
      },

      h2: {
        fontSize: 5,
      },

      h3: {
        fontSize: 4,
      },

      h4: {
        fontSize: 3,
      },

      h5: {
        fontSize: 2,
      },

      h6: {
        fontSize: 1,
      },

      "h1, h2, h3, h4": {
        margin: "1.414em 0 0.5em",
        fontWeight: "bold",
        color: "heading",
        lineHeight: "1.42",
      },

      small: { fontSize: "0.707em" },
      "img, canvas, iframe, video, svg, select, textarea": {
        maxWidth: "100%",
      },

      div: { width: "100%" },
      "div img": { width: "100%" },

      "blockquote p": {
        fontSize: "1.5em",
        fontStyle: "italic",
        margin: "1em auto 1em",
        maxWidth: "48em",
      },

      li: { marginLeft: "2em" },

      "pre, code": { fontFamily: 'Menlo, Monaco, "Courier New", monospace' },

      pre: {
        backgroundColor: "#fafafa",
        fontSize: "0.8em",
        overflowX: "scroll",
        padding: "1.125em",
      },

      "a, a:visited": { color: "primary" },
      "a:hover, a:focus, a:active": { color: "#2980b9" },
    }}
    {...props}
  />
)
