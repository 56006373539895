import React from "react"
import * as Style from "./ArticleCallToAction.styles"
import NewsletterIcon from "@images/svg/newsletterIcon.svg"
import { Input } from "@rebass/forms"
import { useTranslate } from "@utils/hooks/useTranslate"
import Translate from "@components/Translate"
import { useSubscribeToNewsletterForm } from "@utils/hooks/useSubscribeToNewsletterForm"

export default function ArticleCallToAction({ title, subtitle }) {
  const translate = useTranslate()
  const {
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
  } = useSubscribeToNewsletterForm()

  return (
    <Style.Wrapper>
      <Style.Title>{title}</Style.Title>
      <Style.Subtitle>{subtitle}</Style.Subtitle>
      <Style.Hr />
      <Style.ImageFormWrapper>
        <Style.FormWrapper as="form" onSubmit={handleSubmit}>
          <Input
            variant="borderedInput"
            type="text"
            required
            name="name"
            onChange={handleChange}
            value={values.name}
            placeholder={translate("newsletter.namePlaceholder")}
            mb={3}
          />

          <Input
            variant="borderedInput"
            type="email"
            required
            name="email"
            onChange={handleChange}
            value={values.email}
            placeholder={translate("newsletter.emailPlaceholder")}
            mb={3}
          />

          <Style.SubmitButton type="submit" desabilitado={isSubmitting}>
            <Translate id="newsletter.sendButton" />
          </Style.SubmitButton>
        </Style.FormWrapper>
        <NewsletterIcon />
      </Style.ImageFormWrapper>
    </Style.Wrapper>
  )
}
