export default (slug, message) => [
  {
    link: `https://www.facebook.com/sharer.php?u=https://www.x-apps.com.br${slug}`,
    icon: "facebook",
  },
  {
    link: `https://www.linkedin.com/sharing/share-offsite/?url=https://www.x-apps.com.br${slug}`,
    icon: "linkedin",
  },
  {
    link: `https://www.twitter.com/intent/tweet?text=${
      message ? message + " " : ""
      }https://www.x-apps.com.br${slug}`,
    icon: "twitter",
  },
  {
    link: `https://api.whatsapp.com/send/?text=https://www.x-apps.com.br${slug}`,
    icon: "whatsapp",
  },
]
