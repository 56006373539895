import React from "react"
import rehypeReact from "rehype-react"
import ArticleCallToAction from "@components/ArticleCallToAction"
import YoutubeVideo from "@components/YoutubePlayer"

export const customRenderMarkdown = new rehypeReact({
  createElement: React.createElement,
  components: {
    "youtube-embeded": YoutubeVideo,
    "subscribe-to-newsletter": ArticleCallToAction,
  },
}).Compiler
