import Newsletter from "@components/Newsletter"
import PostItem from "@components/PostItem"
import Translate from "@components/Translate"
import React from "react"
import * as Style from "./AsideBar.styles"

function AsideBar({ data: posts }) {
  return (
    <Style.Wrapper>
      <Newsletter />
      {!!posts.length && (
        <>
          <Style.Title>
            <Translate id="post.similarPosts" />
          </Style.Title>
          <Style.PostsGrid>
            {posts.map(({ node }, index) => (
              <PostItem
                height={150}
                key={index}
                slug={node.fields.slug}
                category={node.frontmatter.category}
                categorySlug={node.fields.categorySlug}
                date={node.frontmatter.date}
                timeToRead={node.timeToRead}
                title={node.frontmatter.title}
                description={node.frontmatter.description}
                image={node.frontmatter.image}
              />
            ))}
          </Style.PostsGrid>
        </>
      )}
    </Style.Wrapper>
  )
}

export default AsideBar
