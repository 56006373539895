import React from "react"
import { Box, Text, Flex } from "rebass"
import { Botao } from "@xapps/design-system"

export const Wrapper = props => (
  <Box
    sx={{
      backgroundColor: "backgroundLightGray",
      borderColor: "grayLight2",
      borderRadius: "box",
      borderWidth: "1px",
      borderStyle: "solid",
      paddingX: 3,
      width: "100%",
      paddingY: 4,
      color: "grayBackgroundText",
    }}
    {...props}
  />
)

export const Title = props => (
  <Text
    sx={{
      fontFamily: "heading",
      fontWeight: "bold",
      fontSize: 4,
      mb: 1,
    }}
    {...props}
  />
)

export const Subtitle = props => (
  <Text
    sx={{
      fontFamily: "heading",
      fontSize: 2,
    }}
    {...props}
  />
)

export const Hr = props => (
  <Box
    as="hr"
    sx={{
      borderTopColor: "grayLight2",
      borderTopWidth: "1px",
      borderTopStyle: "solid",
      my: 3,
    }}
    {...props}
  />
)

export const ImageFormWrapper = props => (
  <Flex
    sx={{
      justifyContent: "space-between",
      alignItems: "center",
      "*:first-child": {
        mr: [0, 0, 3],
      },
      svg: {
        display: ["none", "none", "inline-block"],
      },
    }}
    {...props}
  />
)

export const FormWrapper = props => (
  <Box
    sx={{
      width: "100%",
    }}
    {...props}
  />
)

export const SubmitButton = props => (
  <Botao variant="apoio" style={{ borderRadius: 50 }} {...props} />
)
